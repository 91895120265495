import React from "react";

class Header extends React.Component {

    render() {
        return <div className="menu-fixed-top">
            <div className="container">
                <a className="mr-3" href="https://szansaspotkania.pl/o-nas/">O nas</a>
                <a className="mr-3" href="https://szansaspotkania.pl/kontakt">Kontakt</a>
                <a className="" href="https://szansaspotkania.pl/wesprzyj">WESPRZYJ NAS</a>
            </div>
        </div>;
    }

}

export default Header;
