import React from "react";

const MODE_ASK = 0;
const MODE_WAIT = 1;
const MODE_ANSWER = 2;
const MODE_BLOCKED = 3;
const MODE_INACTIVE = 4;

class Content extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            mode: this.findGetParameter("questionId") === null ? MODE_ASK : MODE_WAIT,
            askInProgress: false
        };
    }

    componentDidMount() {
        const questionId = this.findGetParameter("questionId");
        if (questionId != null) {
            this.loadAnswer(questionId);
        }
        this.verifyServerStatus();
    }

    verifyServerStatus() {
        fetch(new Request(process.env.REACT_APP_API_REST_URL + "/diagnostics/status", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        }))
            .then(result => {
                if (result.ok) {
                    return result.json();
                } else {
                    throw new Error(result.statusText);
                }
            })
            .then(result => {
                if (!result.ok) {
                    this.setState({mode: MODE_INACTIVE});
                }
            })
            .catch(reason => this.setState({mode: MODE_INACTIVE}));
    }

    findGetParameter(parameterName) {
        const parameters = window.location.search.substr(1).split("&");
        for (let i = 0; i < parameters.length; ++i) {
            const parameter = parameters[i].split("=");
            if (parameter[0] === parameterName) {
                return decodeURIComponent(parameter[1]);
            }
        }
        return null;
    }

    loadAnswer(questionId) {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/questions/" + questionId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    return result.json();
                } else {
                    throw Error(result.statusText);
                }
            })
            .then(result => {
                if (result.blocked) {
                    this.setState({
                        mode: MODE_BLOCKED
                    });
                } else {
                    this.setState({
                        mode: MODE_ANSWER,
                        answer: result.answer
                    });
                }
            });
    }

    render() {
        return <div className="container poradnia-wrap">
            <img className="logo" src="img/logo.png" alt="Szansa Spotkania"/>
            <h1 className="poradnia-title">Poradnia ONLINE</h1>
            {this.state.mode === MODE_ASK && <p>
                Konsultacje przez internet są nowatorską metodą udzielania pomocy małżonkom.<br/>
                Należy zawsze pamiętać, że porada on line nie zastąpi osobistej rozmowy.<br/>
                Jest ona pomocą dla osób, które aktualnie nie mogą skorzystać ze stacjonarnej formy pomocy.
            </p>}
            {this.state.mode === MODE_ASK && <p>Na pytania odpowiadają małżonkowie we współpracy z kapłanem.</p>}
            {this.state.mode === MODE_ASK && <p>
                Małżonkowie pracują w poradni jako woluntariusze kosztem swojego czasu wolnego.<br/>
                Ich trud i zaangażowanie można wynagrodzić wpłacając darowiznę po otrzymaniu porady na rzecz
                Apostolstwa Małżeństw „Szansa Spotkania”.<br/>
                Bank BNP Paribas SA 87 1600 1462 1847 0283 1000 0081
            </p>}
            {this.state.mode === MODE_ASK && <hr/>}
            {this.state.mode === MODE_ASK &&
                <p>Aby złożyć zapytanie do naszej Poradni Online skorzystaj z poniższego formularza.</p>}
            {this.state.mode === MODE_ASK && <div className="question-form">
                <form id="consult_form" name="consult_form">
                    <div className="qbigbox">
                        <div className="qbox question-name">
                            <label htmlFor="qname">Imię / Imiona: </label>
                            <div className="input"><input type="text" name="qname" id="qname" size="44"/></div>
                        </div>
                        <div className="qbox question-email">
                            <label htmlFor="qemail">Adres e-mail: </label>
                            <div className="input">
                                <input type="text" name="qemail" id="qemail" size="44"/>
                            </div>
                        </div>
                    </div>
                    <div className="qbigbox">
                        <div className="qbox question-question">
                            <label htmlFor="qquestion">Treść pytania: </label>
                            <textarea name="qquestion" id="qquestion" rows="12" style={{width: "84%"}}></textarea>
                        </div>
                    </div>
                    <input type="hidden" value="120" name="tstzzz" id="tstzzz"/>
                    <input type="hidden" value="" name="adip" id="adip"/>
                    <div className="qbigbox" style={{width: "44px", float: "right"}}>
                        <div className="qbox question-submit">
                            <div className="checkbox-wrapper">
                                <input type="checkbox" id="check1"/>
                                <label htmlFor="check1">(*) Wyrażam zgodę na przetwarzanie moich danych osobowych
                                    zgodnie z ustawą o ochronie danych osobowych w celach związanych z realizacją
                                    zgłoszenia kontaktowego. Administratorem danych jest br. Ksawery Knotz OFM Cap,
                                    ul. Korzeniaka 16, 30-298 Kraków. Równocześnie akceptuję <a
                                        href="https://www.szansaspotkania.pl/polityka-prywatnosci/" target="_blank"
                                        rel="noopener noreferrer">
                                        Politykę Prywatności</a> serwisu Szansa Spotkania.</label>
                            </div>
                            <button className="ask-question" disabled={this.state.askInProgress}
                                    onClick={this.tryAskQuestion.bind(this)}>
                                {this.state.askInProgress ? "Wysyłanie..." : "Wyślij"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>}
            {this.state.mode === MODE_WAIT && <div className="question-confirm">
                <h1 className="qc">Wysyłanie pytania.</h1>
                <div className="qc-info">
                    Pytanie zostało wysłane. Prosimy oczekiwać na odpowiedź z naszej strony.
                </div>
            </div>}
            {this.state.mode === MODE_ANSWER && <div className="answer-content">
                <h1>Odpowiedź na zadane pytanie.</h1>
                <div className="answer">
                    {this.state.answer //
                        .split("\n") //
                        .map(line => <p>{line}</p>)}
                </div>
            </div>}
            {this.state.mode === MODE_BLOCKED && <div className="answer-content">
                <h1>Odpowiedź nie jest już dostępna.</h1>
            </div>}
            {this.state.mode === MODE_INACTIVE &&
                <p>System jest nieaktywny, proszę o cierpliwość lub bezpośredni kontakt z administratorem.</p>}
        </div>;
    }

    tryAskQuestion(event) {
        if (this.validateForm()) {
            this.askQuestion();
        }
        event.preventDefault();
    }

    validateForm() {
        if (document.getElementById("qname").value === "") {
            alert("Należy podać imię.");
            return false;
        }
        if (document.getElementById("qemail").value === "") {
            alert("Należy podać adres e-mail.");
            return false;
        }
        if (!this.validateEmail(document.getElementById("qemail").value)) {
            alert("Adres e-mail jest nieprawidłowy.");
            return false;
        }
        if (document.getElementById("qquestion").value === "") {
            alert("Nie zadałeś pytania.");
            return false;
        }
        if (!document.getElementById('check1').checked) {
            alert("Należy zaakceptować Politykę Prywatności.");
            return false;
        }
        return true;
    }

    validateEmail(email) {
        return email.search(/^([A-Za-z0-9_\-.!#$%&*+/\\=?^`~|{}])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/) !== -1;
    }

    askQuestion() {
        this.setState({askInProgress: true});
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/questions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify({
                name: document.getElementById("qname").value,
                email: document.getElementById("qemail").value,
                content: document.getElementById("qquestion").value
            })
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.setState({mode: MODE_WAIT});
                }
                this.setState({askInProgress: false});
            });
    }

}

export default Content;
