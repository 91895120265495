import React from "react";

class Footer extends React.Component {

    render() {
        return <footer className="footer mt-auto py-3">
            <div className="container text-center">
                <span className="text-light">© Apostolstwo Małżeństw „Szansa Spotkania” - 2021</span>
            </div>
            <div className="container text-center">
                <a href="https://wercia.pl" target="_blank">wercia.pl</a>
            </div>
        </footer>;
    }

}

export default Footer;
